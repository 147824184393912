<template>
  <div>
    <TitleWrapper :filter-option="false" title="Email" :display-breadcrumb="true" />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <InputBox
        type="text"
        name="Email"
        title="Email"
        label="Email"
        placeholder="jessiarose"
        class="md:w-64 mt-4 w-full"
      />
      <InputBox
        type="text"
        name="Temporary Email"
        title="Temporary Email"
        label="Temporary Email"
        placeholder="jessiarose"
        class="md:w-64 mt-4 w-full"
      />
      <div class="md:inline-flex gap-2 text-sm rtl:flex-row-reverse">
        <div class="flex cursor-pointer items-center" @click="verifyEmail">
          <button
            class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          >
            Change Email
          </button>
        </div>
      </div>
      <verifyEmailModal
        v-if="modalVerifyEmail"
        :modal="modalVerifyEmail"
        @toggle="verifyEmail"
      ></verifyEmailModal>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import verifyEmailModal from '@src/router/views/settings/security-settings/modals/verifyEmailModal.vue'

export default {
  components: {
    TitleWrapper,
    InputBox,
    verifyEmailModal,
  },
  data() {
    return {
      modalVerifyEmail: false,
    }
  },
  methods: {
    verifyEmail() {
      this.modalVerifyEmail = !this.modalVerifyEmail
    },
  },
}
</script>
