<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="dashboard">Verify Your Email</span>
    </template>
    <template v-slot>
      <div class="py-3 px-6">
        <div class="mt-3">
          <h1 class="font-bold font-roboto text-text-primary-navy-blue text-center m-1">
            Verify Your Email
          </h1>
          <p class="font-roboto text-menu text-base text-center">
            We have sent you a OTP code on your email. Enter your OTP code here
          </p>
          <div class="my-6 flex justify-center">
            <input
              class="rounded-lg text-center mx-1 p-3 font-medium text-xl text-primary-green"
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1} "
            />
            <input
              class="rounded-lg text-center mx-1 p-3 font-medium text-xl text-primary-green"
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
            />
            <input
              class="rounded-lg text-center mx-1 p-3 font-medium text-xl text-primary-green"
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
            />
            <input
              class="rounded-lg text-center mx-1 p-3 font-medium text-xl text-primary-green"
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
            />
          </div>
          <p class="font-roboto text-menu text-base text-center">
            Didn't you receive any code?
            <span class="font-bold text-primary-green">Resend OTP</span>
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="dashboard"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        :disabled="isLoading"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        :disabled="isLoading"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(formCheck(invalid))"
      >
        <template v-if="isLoading">
          <Loader />
        </template>
        <template v-else>
          <span v-i18n="dashboard">Verify</span>
        </template>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    UiModalContainer,
    Loader,
    // ValidationObserver,
  },
  props: {
    modal: { type: Boolean },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    ...mapState('instituteCount', ['instituteCount']),
    modalOpen() {
      return this.modal
    },
  },
  methods: {
    ...mapActions('campuses', ['createCampus']),
    ...mapActions('layout', ['getCampusList']),
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'Verify':
          break
      }
    },
    async formCheck(invalid) {
      if (!invalid) {
        try {
          this.$store.commit('layout/IS_LOADING', true)
          await this.createCampus(this.campusFormData).then(() => {
            this.$store.commit('layout/IS_LOADING', false)
            this.getCampusList()
            this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'campuses_count')
          })
          this.$emit('toggle')
        } catch (err) {
          this.$store.commit('layout/IS_LOADING', false)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  background: #f5f5f7 0% 0% no-repeat padding-box;
  outline: none;
  transition: all 0.2s ease-in-out;
  // border-radius: 9px;

  &:focus {
    background: #c8e6c8 0% 0% no-repeat padding-box;
  }
  &::selection {
    background: #c8e6c8 0% 0% no-repeat padding-box;
  }
}
</style>
